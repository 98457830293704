.iframe-review-holder {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 999999 !important;
  border: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}